import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { default as SA } from '../Activity/Activity.styled';

import AgeYearInput from './AgeYearInput';
import BirthdayInput from './BirthdayInput';
import ActivityWelcome from '../ActivityWelcome/ActivityWelcome';
import Logo from '../Logo/Logo';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { PostTriageProgressInputAge } from '../../helpers/services/CallPostTriageProgress';
import { createDateForDob } from '../../helpers/support/createDateForDob';
import { getLabel } from '../../helpers/constants/getLabels';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import type { ActivityAnswerAgeSelector, ActivityStepAgeSelector } from '../../models';
import { ActivityProps } from '../Activity';
import StartButton from '../StartButton/StartButton';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';
import { useTheme } from 'styled-components';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';

interface ActivityAgeProps extends ActivityProps {
	currentStep: ActivityStepAgeSelector;
	answer?: ActivityAnswerAgeSelector;
	title: string | JSX.Element;
	info?: string | JSX.Element;
	step?: number;
}

const ActivityAge: FunctionComponent<ActivityAgeProps> = (props) => {
	const { handleActivityResponse: baseHandleActivityResponse, handleNext, setHandleNext, setDisableNext } = props;
	const [{ settings }, dispatch] = useStateContext();
	const [answer, setAnswer] = useState<ActivityAnswerAgeSelector>();
	const stepNumber = useCurrentStepNumber(props.currentStep.id);
	const theme = useTheme();
	const isMobile = useWindowSize().width < theme.breakpoints.medium;
	const [, widgetIsSmall] = useWidgetIsNarrow();

	const confirmButtonClassName = props.modalOpen ? null : 'mindd-widget-startbutton';

	const handleActivityResponse = useCallback(async () => {
		if (answer) {
			const triageProgress = {
				updateAge: true,
				age: parseInt(answer.age.age, 10),
				birthdate: createDateForDob(answer.dob) as Date
			} as PostTriageProgressInputAge;

			setDisableNext(true);
			const next = await baseHandleActivityResponse(answer, triageProgress);
			setDisableNext(false);

			if (next !== false) {
				handleNext();
			} else {
				//TODO: Reset answer to props.answer
			}
		} else if (!props.modalOpen) {
			// Start session and open modal
			await baseHandleActivityResponse(undefined);
		}
	}, [answer, props.isFirstActivity, baseHandleActivityResponse, handleNext, setDisableNext]);

	useEffect(() => {
		if (answer) {
			setHandleNext(() => async () => {
				await handleActivityResponse();
			});
		}
	}, [answer, setHandleNext, handleActivityResponse]); // answer, props.answer, props.currentStep.id, props.modalOpen, dispatch, setHandleNext, handleActivityResponse

	const handleAnswerChange = (answer: ActivityAnswerAgeSelector) => {
		if (props.isLastActivity) {
			// Set initial answer to enable next
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.currentStep.id,
				answer
			});
		}
		// Set next disabled when answer is invalid, only valid answers have a value
		setDisableNext(!answer);
		setAnswer(answer);
	};

	const [widgetIsNarrow] = useWidgetIsNarrow();

	return (
		<SA.ActivityGroup $modalClosed={!props.modalOpen} $showWelcomeText={settings.widget_showWelcomeText} $narrow={widgetIsSmall}>
			{settings.widget_showWelcomeText && props.isFirstActivity && !props.modalOpen && <ActivityWelcome modalOpen={props.modalOpen} />}

			<div>
				<SA.ActivityBubble $modalOpen={props.modalOpen ?? false}>
					<ActivityBubbleTitle
						title={settings.askForBirthday ? props.title : getLabel('QuestionWhatAgeInYears', settings.applicationTexts, true)}
						info={settings.askForBirthday ? props.info : getLabel('QuestionWhatAgeInYearsInfo', settings.applicationTexts, true, true)}
						isFirstActivity={props.isFirstActivity}
						isLastActivity={props.isLastActivity}
						modalOpen={props.modalOpen}
						disabled={props.disabled}
						limitHeight={!props.modalOpen}
						titleAriaHidden={true}
						widgetIsNarrow={widgetIsNarrow}
						stepNumber={stepNumber}
					/>
					{settings.askForBirthday ? (
						<BirthdayInput
							confirmButtonClassName={confirmButtonClassName ?? ''}
							disabled={props.disabled}
							step={props.step}
							title={props.title}
							answer={props.answer}
							onChange={(a) => handleAnswerChange(a as ActivityAnswerAgeSelector)}
						/>
					) : (
						<AgeYearInput
							confirmButtonClassName={confirmButtonClassName ?? ''}
							disabled={props.disabled}
							step={props.step}
							title={props.title}
							answer={props.answer}
							onChange={(a) => handleAnswerChange(a as ActivityAnswerAgeSelector)}
						/>
					)}

					{!props.modalOpen && !settings.hide_footer && props.isFirstActivity && <Logo align="end" size={145} />}
				</SA.ActivityBubble>

				{!props.modalOpen && props.isFirstActivity && <StartButton onStart={() => void handleActivityResponse()} />}
				{!props.modalOpen && props.isFirstActivity && isMobile && <TermsOfUse modalOpen={props.modalOpen} />}
			</div>
		</SA.ActivityGroup>
	);
};

export default ActivityAge;
