import React, { FunctionComponent, useState } from 'react';

import SVG from 'react-inlinesvg';
import Select from 'react-select';
import S from './LanguageSelector.styled';
import { useTheme } from 'styled-components';
import { translateConversation } from '../../helpers/constants/translateConversation';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetApplicationTexts, CallGetChannelSettings } from '../../helpers/services';
import useSelectStyles from '../../helpers/styled/Select';
import type { Language, LanguageApplicationTexts, Settings } from '../../models';

interface LanguageSelectorProps {
	disabled?: boolean;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = (props) => {
	const [{ settings, languages, conversation }, dispatch] = useStateContext();
	const themeContext = useTheme();
	const selectStyles = useSelectStyles<Language>(themeContext, { control: { width: 200 } });
	const [loading, setLoading] = useState(false);

	const { selectedLanguage } = settings;
	const { disabled } = props;

	const handleLanguageSelection = async (language: Language) => {
		setLoading(true);

		const previousSelectedLanguage = { ...settings.selectedLanguage };
		const newSelectedLanguage = { ...language };

		const channelSettings = await CallGetChannelSettings(settings.ApiKey, {
			languageCode: language.code
		});
		const applicationTexts = await CallGetApplicationTexts(settings.ApiKey, {
			languageCode: language.code
		});
		// TODO: Determine what to do when API calls fail and return undefined, should expect error when loading the widget (handled) so should be rare
		const newConversation = translateConversation(conversation, applicationTexts?.applicationTexts ?? []);

		// use welcomeText from channnel settings, otherwise from widget config
		const welcome_text = settings.welcome_text ?? {};
		if (channelSettings?.welcomeText) {
			(welcome_text as LanguageApplicationTexts)[newSelectedLanguage.code] = channelSettings.welcomeText;
		}

		// use questionWhatGender from channnel settings, otherwise from widget config
		const questionWhatGender = settings.labels?.QuestionWhatGender ?? {};
		if (channelSettings?.questionWhatGender) {
			(questionWhatGender as LanguageApplicationTexts)[newSelectedLanguage.code] = channelSettings.questionWhatGender;
		}

		const allConfigSettings = {
			...settings,
			...channelSettings,
			...applicationTexts,
			selectedLanguage: newSelectedLanguage,
			welcome_text: welcome_text,
			labels: {
				...settings.labels,
				QuestionWhatGender: questionWhatGender
			},
			branding: {
				...settings.branding,
				name: channelSettings?.brandingName || settings.branding?.name
			}
		};

		delete allConfigSettings.brandingName;
		delete allConfigSettings.questionWhatGender;
		delete allConfigSettings.welcomeText;

		// update
		dispatch({ type: 'updateSettings', settings: allConfigSettings as Settings });
		dispatch({ type: 'updateConversationActivities', conversation: newConversation });
		(settings.target as Element).dispatchEvent(
			new CustomEvent('minddWidgetLanguageChanged', {
				bubbles: true,
				detail: { previousLanguage: previousSelectedLanguage, newLanguage: newSelectedLanguage }
			})
		);
		if (typeof settings.onLanguageChanged === 'function') {
			settings.onLanguageChanged.call(null, previousSelectedLanguage, language);
		}

		setLoading(false);
	};

	return (
		<S.Container>
			<Select<Language>
				classNamePrefix={'language-selector'}
				options={languages}
				formatOptionLabel={(option) => (
					<div style={{ display: 'flex', gap: themeContext.spacings.small, alignItems: 'center' }}>
						<SVG src={option.flagSvg ?? ''} style={{ width: 32, height: 16 }} />
						<div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{option.name}</div>
					</div>
				)}
				isOptionSelected={(option) => option === selectedLanguage}
				styles={selectStyles}
				value={selectedLanguage}
				onChange={(val) => void handleLanguageSelection(val as Language)}
				isDisabled={disabled}
				isLoading={loading}
				getOptionLabel={(option) => option.name ?? ''}
				isSearchable={false}
			/>
		</S.Container>
	);
};

export default LanguageSelector;
