import React, { FunctionComponent, useEffect, useCallback, useId, useState, useMemo } from 'react';
import { default as SA } from '../Activity/Activity.styled';
import SP from '../Procedure/Procedure.styled';
import ST from '../ActivityTriage/ActivityTriage.styled';
import ActivityWelcome from '../ActivityWelcome/ActivityWelcome';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Logo from '../Logo/Logo';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { PostTriageProgressInputGender } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { getOverrideLabel } from '../../helpers/constants/getOverrideLabel';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import { RadioButtonGroup } from '../Buttons/Buttons';
import type { Gender, LanguageApplicationTexts } from '../../models';
import StartButton from '../StartButton/StartButton';
import { ActivityProps } from '../Activity';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';
import { useTheme } from 'styled-components';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';

interface ActivityGenderProps extends ActivityProps {
	title?: string | JSX.Element;
	info?: string | JSX.Element;
	answer?: { gender: Gender } | undefined;
}

const ActivityGender: FunctionComponent<ActivityGenderProps> = (props) => {
	const { handleActivityResponse: baseHandleActivityResponse, handleNext, setHandleNext, setDisableNext } = props;

	const [{ settings, profile, refs }, dispatch] = useStateContext();
	const label: string | LanguageApplicationTexts | undefined =
		settings.labels && settings.labels.QuestionWhatGender ? settings.labels.QuestionWhatGender : undefined; // note: undefined here instead of null
	const bubbleText = getOverrideLabel(label, props.title ?? '', settings.selectedLanguage.code, false);
	const maleQuestionTitle = getLabel('GenderSelectorMaleLabel', settings.applicationTexts, true) || 'Man';
	const maleAnswerValue = 'Male';
	const femaleQuestionTitle = getLabel('GenderSelectorFemaleLabel', settings.applicationTexts, true) || 'Vrouw';
	const femaleAnswerValue = 'Female';
	const [selected, setSelected] = useState<Gender | undefined>(profile.gender ?? props.answer?.gender);
	const isMaleChecked = useMemo(() => selected === maleAnswerValue, [selected, maleAnswerValue]);
	const isFemaleChecked = useMemo(() => selected === femaleAnswerValue, [selected, femaleAnswerValue]);
	const [widgetIsNarrow, widgetIsSmall] = useWidgetIsNarrow();
	const stepNumber = useCurrentStepNumber(props.id);
	const theme = useTheme();
	const isMobile = useWindowSize().width < theme.breakpoints.medium;

	const handleActivityResponse = useCallback(async () => {
		if (selected) {
			setDisableNext(true);
			const next = await baseHandleActivityResponse({ gender: selected }, { updateGender: true, gender: selected } as PostTriageProgressInputGender);
			setDisableNext(false);
			if (next !== false) {
				dispatch({
					type: 'updateProfile/Gender',
					gender: selected
				});
				handleNext();
			} else {
				setSelected(props.answer?.gender);
			}
		} else if (!props.modalOpen) {
			// Starts session and open modal
			void baseHandleActivityResponse(undefined);
		}
	}, [selected, props.answer, props.isFirstActivity, baseHandleActivityResponse, dispatch, handleNext, setDisableNext]);

	useEffect(() => {
		if (selected) {
			setHandleNext(() => async () => {
				await handleActivityResponse();
			});
		}
	}, [selected, setHandleNext, handleActivityResponse]);

	const handleChange = (value: Gender) => {
		if (props.isLastActivity) {
			// Set initial answer to enable next
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.id,
				answer: { gender: value }
			});
		}
		setSelected(value);
	};

	const nameAttr = useId();

	return (
		<SA.ActivityGroup $modalClosed={!props.modalOpen} $showWelcomeText={settings.widget_showWelcomeText} $narrow={widgetIsSmall}>
			{settings.widget_showWelcomeText && props.isFirstActivity && !props.modalOpen && <ActivityWelcome modalOpen={props.modalOpen} />}

			<div>
				<SA.ActivityBubble $modalOpen={props.modalOpen ?? false} $widgetIsNarrow={widgetIsNarrow}>
					<ActivityBubbleTitle
						title={bubbleText}
						info={props.info}
						isFirstActivity={props.isFirstActivity}
						isLastActivity={props.isLastActivity}
						modalOpen={props.modalOpen}
						disabled={props.disabled}
						widgetIsNarrow={widgetIsNarrow}
						stepNumber={stepNumber}
					/>
					<RadioButtonGroup>
						<ST.CheckBox checked={isMaleChecked}>
							<SP.Field
								type="radio"
								name={nameAttr}
								id={`gender_${maleAnswerValue}_${nameAttr}`}
								disabled={props.disabled}
								onChange={() => handleChange(maleAnswerValue)}
								checked={isMaleChecked}
							/>
							<SP.Label htmlFor={`gender_${maleAnswerValue}_${nameAttr}`}>{maleQuestionTitle}</SP.Label>
						</ST.CheckBox>
						<ST.CheckBox checked={isFemaleChecked}>
							<SP.Field
								type="radio"
								name={nameAttr}
								id={`gender_${femaleAnswerValue}_${nameAttr}`}
								disabled={props.disabled}
								onChange={() => handleChange(femaleAnswerValue)}
								checked={isFemaleChecked}
							/>
							<SP.Label htmlFor={`gender_${femaleAnswerValue}_${nameAttr}`}>{femaleQuestionTitle}</SP.Label>
						</ST.CheckBox>
					</RadioButtonGroup>

					{!props.modalOpen && !settings.hide_footer && props.isFirstActivity && <Logo align="end" size={145} />}
				</SA.ActivityBubble>

				{!props.modalOpen && props.isFirstActivity && <StartButton onStart={() => void handleActivityResponse()} />}
				{!props.modalOpen && props.isFirstActivity && (isMobile || widgetIsSmall) && <TermsOfUse modalOpen={props.modalOpen} />}
			</div>
		</SA.ActivityGroup>
	);
};

export default ActivityGender;
