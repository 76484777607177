import React from 'react';
import styled from 'styled-components';
import type { DivProps } from '../../models/widget/Styled';
import { ActivityBubbleProps, default as SA } from '../Activity/Activity.styled';

const ClearActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		background-color: transparent !important;
		font-size: 18px;
		//todo color dynamic?
		color: #60606a;
		box-shadow: none;
		padding: 0;
		border: none;
	}
`;

const ClearActivityBubbleWhenOpen = styled(SA.ActivityBubble)`
	&&& {
		background-color: transparent;
		box-shadow: none;
		padding: 0;
		border: none;
	}
`;

export default {
	ClearActivityBubble: (props: DivProps<ActivityBubbleProps>) => (
		// @ts-expect-error prop types are correct
		<ClearActivityBubble {...props} data-minddclass="clearactivitybubble">
			{props.children}
		</ClearActivityBubble>
	),
	ClearActivityBubbleWhenOpen: (props: DivProps<ActivityBubbleProps>) => (
		// @ts-expect-error prop types are correct
		<ClearActivityBubbleWhenOpen {...props} data-minddclass="clearactivitybubblewhenopen">
			{props.children}
		</ClearActivityBubbleWhenOpen>
	)
};
